define("music-machine/components/synth/switch/on-off", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (array false true) as |options|}}
    <Synth::Switch
      @options={{options}}
      @value={{@value}}
      @onChange={{@onChange}}
      as |option|
    >
      {{#if (eq option true)}}
        <span class="{{if @value 'text-brand-primary'}}">on</span>
      {{else}}
        off
      {{/if}}
    </Synth::Switch>
  {{/let}}
  
  */
  {
    "id": "6liJmHJx",
    "block": "[[[44,[[28,[37,1],[false,true],null]],[[[1,\"  \"],[8,[39,2],null,[[\"@options\",\"@value\",\"@onChange\"],[[30,1],[30,2],[30,3]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,4],[[30,4],true],null],[[[1,\"      \"],[10,1],[15,0,[29,[[52,[30,2],\"text-brand-primary\"]]]],[12],[1,\"on\"],[13],[1,\"\\n\"]],[]],[[[1,\"      off\\n\"]],[]]],[1,\"  \"]],[4]]]]],[1,\"\\n\"]],[1]]]],[\"options\",\"@value\",\"@onChange\",\"option\"],false,[\"let\",\"array\",\"synth/switch\",\"if\",\"eq\"]]",
    "moduleName": "music-machine/components/synth/switch/on-off.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});