define("music-machine/utils/synth/envelope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.EnvelopeSettings = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class EnvelopeSettings {
    constructor() {
      _defineProperty(this, "attack", 0.05);

      _defineProperty(this, "decay", 0.1);

      _defineProperty(this, "sustain", 1);

      _defineProperty(this, "release", 0.1);
    }

  }

  _exports.EnvelopeSettings = EnvelopeSettings;

  class Envelope {
    constructor() {
      let settings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;

      _defineProperty(this, "context", void 0);

      _defineProperty(this, "attack", 0.05);

      _defineProperty(this, "decay", 0.1);

      _defineProperty(this, "sustain", 1);

      _defineProperty(this, "release", 0.1);

      if (settings) {
        this.apply(settings);
      } else {
        this.apply(new EnvelopeSettings());
      }
    }

    initialize(audioContext) {
      this.context = audioContext;
    }

    apply(settings) {
      this.attack = settings.attack;
      this.decay = settings.decay;
      this.sustain = settings.sustain;
      this.release = settings.release;
    }

    connect(param) {
      this.param = param;
      this.param.setValueAtTime(0, this.context.currentTime);
    }

    start() {
      const now = this.context.currentTime;
      this.param.cancelScheduledValues(now);
      this.param.setValueAtTime(0, now);
      this.param.linearRampToValueAtTime(1, now + this.attack);
      this.param.linearRampToValueAtTime(this.sustain, now + this.attack + this.decay);
      this.holdValueTime = now + this.attack + this.decay;
    }

    stop() {
      const now = this.context.currentTime;

      if (now > this.holdValueTime) {
        this.param.setValueAtTime(this.sustain, now);
      } else if (this.param.cancelAndHoldAtTime) {
        this.param.cancelAndHoldAtTime(now);
      } else {
        // To support Firefox which doesn't have cancelAndHoldAtTime
        this.param.cancelScheduledValues(now);
        let timeDiff = this.holdValueTime - now;

        if (timeDiff < this.decay) {
          if (this.sustain < 1.0) {
            this.param.setValueAtTime(this.sustain + (1.0 - this.sustain) * (timeDiff / this.decay), now);
          }
        } else {
          timeDiff -= this.decay;
          this.param.setValueAtTime(1.0 - timeDiff / this.attack, now);
        }
      }

      this.param.linearRampToValueAtTime(0, now + this.release);
    }

  }

  _exports.default = Envelope;
});