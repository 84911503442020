define("music-machine/components/synth/wave-form-switch", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (array "sawtooth" "square" "triangle" "sine") as |options|}}
    <Synth::Switch
      @label="Wave"
      @options={{options}}
      @value={{@waveForm}}
      @onChange={{fn (mut @waveForm)}}
      as |option|
    >
      <FaIcon
        @prefix="fas"
        @size="lg"
        @icon={{concat "wave-" (if (eq option "sawtooth") "pulse" option)}}
        class={{class-names
          (if (eq option "sawtooth") "text-light-blue-500")
          (if (eq option "sine") "text-brand-primary")
          (if (eq option "square") "text-emerald-500")
          (if (eq option "triangle") "text-amber-500")
        }}
      />
    </Synth::Switch>
  {{/let}}
  
  */
  {
    "id": "rHELhqxo",
    "block": "[[[44,[[28,[37,1],[\"sawtooth\",\"square\",\"triangle\",\"sine\"],null]],[[[1,\"  \"],[8,[39,2],null,[[\"@label\",\"@options\",\"@value\",\"@onChange\"],[\"Wave\",[30,1],[30,2],[28,[37,3],[[28,[37,4],[[30,2]],null]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,5],[[16,0,[28,[37,6],[[52,[28,[37,8],[[30,3],\"sawtooth\"],null],\"text-light-blue-500\"],[52,[28,[37,8],[[30,3],\"sine\"],null],\"text-brand-primary\"],[52,[28,[37,8],[[30,3],\"square\"],null],\"text-emerald-500\"],[52,[28,[37,8],[[30,3],\"triangle\"],null],\"text-amber-500\"]],null]]],[[\"@prefix\",\"@size\",\"@icon\"],[\"fas\",\"lg\",[28,[37,9],[\"wave-\",[52,[28,[37,8],[[30,3],\"sawtooth\"],null],\"pulse\",[30,3]]],null]]],null],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"]],[1]]]],[\"options\",\"@waveForm\",\"option\"],false,[\"let\",\"array\",\"synth/switch\",\"fn\",\"mut\",\"fa-icon\",\"class-names\",\"if\",\"eq\",\"concat\"]]",
    "moduleName": "music-machine/components/synth/wave-form-switch.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});