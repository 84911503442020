define("music-machine/utils/synth/effects/biquad-filter", ["exports", "music-machine/utils/synth/base-node"], function (_exports, _baseNode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MIN_AMOUNT = _exports.MAX_AMOUNT = _exports.LIMITS = _exports.BiquadFilterSettings = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const MIN_AMOUNT = 0;
  _exports.MIN_AMOUNT = MIN_AMOUNT;
  const MAX_AMOUNT = 22050;
  _exports.MAX_AMOUNT = MAX_AMOUNT;
  const LIMITS = {
    min: MIN_AMOUNT,
    max: MAX_AMOUNT
  };
  _exports.LIMITS = LIMITS;

  class BiquadFilterSettings {
    constructor() {
      _defineProperty(this, "amount", 0.0);
    }

  }

  _exports.BiquadFilterSettings = BiquadFilterSettings;

  class BiquadFilter extends _baseNode.default {
    constructor(type) {
      super();

      _defineProperty(this, "audioContext", void 0);

      _defineProperty(this, "filterNode", void 0);

      _defineProperty(this, "amount", 0.0);

      _defineProperty(this, "_on", false);

      _defineProperty(this, "_threshold", void 0);

      _defineProperty(this, "type", void 0);

      this.type = type;

      switch (type) {
        case 'highpass':
          this._threshold = MIN_AMOUNT;
          break;

        case 'lowpass':
          this._threshold = MAX_AMOUNT;
          break;
      }
    }

    set on(on) {
      if (on === this._on) {
        return;
      }

      this._on = on;

      if (on) {
        this.input.disconnect(this.output);
        this.input.connect(this.filterNode);
        this.filterNode.connect(this.output);
      } else {
        this.input.disconnect(this.filterNode);
        this.filterNode.disconnect(this.output);
        this.input.connect(this.output);
      }
    }

    get frequency() {
      return this.filterNode.frequency;
    }

    initialize(audioContext) {
      super.initialize(audioContext);
      this.filterNode = audioContext.createBiquadFilter();
      this.filterNode.type = this.type;
      this.input.connect(this.output);
    }

    apply(settings) {
      this.amount = settings.amount;

      if (this.amount === this._threshold) {
        this.on = false;
      } else if (this.filterNode) {
        this.on = true;
        this.filterNode.frequency.setTargetAtTime(parseFloat(settings.amount) * parseFloat(settings.amount) * parseFloat(settings.amount) / (MAX_AMOUNT * MAX_AMOUNT), this.audioContext.currentTime, 0.01);
      }
    }

  }

  _exports.default = BiquadFilter;
});