define("music-machine/utils/synth/base-node", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class BaseNode {
    constructor() {
      _defineProperty(this, "audioContext", void 0);

      _defineProperty(this, "input", void 0);

      _defineProperty(this, "output", void 0);
    }

    initialize(audioContext) {
      this.audioContext = audioContext;
      this.input = audioContext.createGain();
      this.output = audioContext.createGain();
    }

    connect(node) {
      return this.output.connect(node);
    }

    disconnect(node) {
      return this.output.disconnect(node);
    }

  }

  _exports.default = BaseNode;
});