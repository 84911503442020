define("music-machine/routes/index", ["exports", "@ember/routing/route", "music-machine/utils/base-64"], function (_exports, _route, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends _route.default {
    setupController(controller) {
      super.setupController(...arguments);

      if (controller.s) {
        controller.initialSettings = (0, _base.base64ToJson)(controller.s);
      }
    }

  }

  _exports.default = IndexRoute;
});