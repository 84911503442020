define("music-machine/utils/synth/effects/distortion", ["exports", "music-machine/utils/synth/base-node"], function (_exports, _baseNode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MIN_AMOUNT = _exports.MAX_AMOUNT = _exports.LIMITS = _exports.DistortionSettings = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const MIN_AMOUNT = 0;
  _exports.MIN_AMOUNT = MIN_AMOUNT;
  const MAX_AMOUNT = 100;
  _exports.MAX_AMOUNT = MAX_AMOUNT;
  const LIMITS = {
    min: MIN_AMOUNT,
    max: MAX_AMOUNT
  };
  _exports.LIMITS = LIMITS;

  class DistortionSettings {
    constructor() {
      _defineProperty(this, "amount", 0.0);
    }

  }

  _exports.DistortionSettings = DistortionSettings;

  class Distortion extends _baseNode.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "audioContext", void 0);

      _defineProperty(this, "distortionNode", void 0);

      _defineProperty(this, "amount", 0.0);

      _defineProperty(this, "_on", false);
    }

    makeDistortionCurve(amount) {
      const k = amount * amount / MAX_AMOUNT;
      const sampleRate = 44100;
      const curve = new Float32Array(sampleRate);
      let i = 0;
      let x;

      for (; i < sampleRate; ++i) {
        x = i * 2 / sampleRate - 1;
        curve[i] = (3 + k) * Math.atan(Math.sinh(x * 0.25) * 5) / (Math.PI + k * Math.abs(x));
      }

      return curve;
    }

    set on(on) {
      if (on === this._on) {
        return;
      }

      this._on = on;

      if (on) {
        this.input.disconnect(this.output);
        this.input.connect(this.distortionNode);
        this.distortionNode.connect(this.output);
      } else {
        this.input.disconnect(this.distortionNode);
        this.distortionNode.disconnect(this.output);
        this.input.connect(this.output);
      }
    }

    initialize(audioContext) {
      super.initialize(audioContext);
      this.distortionNode = audioContext.createWaveShaper();
      this.distortionNode.curve = this.makeDistortionCurve(this.amount);
      this.distortionNode.oversample = '4x';
      this.input.connect(this.output);
    }

    apply(settings) {
      this.amount = settings.amount;

      if (this.amount === MIN_AMOUNT) {
        this.on = false;
      } else if (this.distortionNode) {
        this.on = true;
        this.distortionNode.curve = this.makeDistortionCurve(settings.amount);
      }
    }

  }

  _exports.default = Distortion;
});