define("music-machine/helpers/add", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.default = void 0;

  function add(numbers) {
    return numbers.reduce((value, acc) => value + acc, 0);
  }

  var _default = _helper.default.helper(add);

  _exports.default = _default;
});