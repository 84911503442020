define("music-machine/components/synth/keyboard", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "music-machine/constants/notes", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _notes, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex gap-2 h-[225px] overflow-hidden" local-class="keyboard">
    {{#each (array 0 1) as |octave|}}
      {{#each this.groupedKeys as |keys|}}
        <div class="flex h-full relative grow">
          {{#each keys as |key|}}
            <button
              type="button"
              class="grow text-base-grey-43"
              local-class="key {{key.cssClass}} {{if
                (compute (fn this.isKeyPlayed key octave @currentNotes))
                (concat key.cssClass "--active")
              }}"
              {{on "mouseover" (fn this.handleMouseOver key octave)}}
              {{on "mousedown" (fn @onMouseDown key octave)}}
              {{on "mouseup" (fn @onMouseUp key octave)}}
              {{on "mouseout" (fn @onMouseUp key octave)}}
            >
              <span
                class="absolute bottom-12 w-full text-center left-0 lowercase"
              >
                {{concat (if (eq octave 1) "⇧ " "") key.letter}}
              </span>
            </button>
          {{/each}}
        </div>
      {{/each}}
    {{/each}}
  </div>
  
  */
  {
    "id": "AM5eRx74",
    "block": "[[[10,0],[15,0,[29,[\"flex gap-2 h-[225px] overflow-hidden \",[28,[37,0],[\"keyboard\"],[[\"from\"],[\"music-machine/components/synth/keyboard.module.scss\"]]]]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[28,[37,3],[0,1],null]],null]],null],null,[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"groupedKeys\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"flex h-full relative grow\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,2]],null]],null],null,[[[1,\"          \"],[11,\"button\"],[16,0,[29,[\"grow text-base-grey-43 \",[28,[37,0],[[28,[37,4],[\"key \",[30,3,[\"cssClass\"]],\" \",[52,[28,[37,6],[[28,[37,7],[[30,0,[\"isKeyPlayed\"]],[30,3],[30,1],[30,4]],null]],null],[28,[37,4],[[30,3,[\"cssClass\"]],\"--active\"],null]]],null]],[[\"from\"],[\"music-machine/components/synth/keyboard.module.scss\"]]]]]],[24,4,\"button\"],[4,[38,8],[\"mouseover\",[28,[37,7],[[30,0,[\"handleMouseOver\"]],[30,3],[30,1]],null]],null],[4,[38,8],[\"mousedown\",[28,[37,7],[[30,5],[30,3],[30,1]],null]],null],[4,[38,8],[\"mouseup\",[28,[37,7],[[30,6],[30,3],[30,1]],null]],null],[4,[38,8],[\"mouseout\",[28,[37,7],[[30,6],[30,3],[30,1]],null]],null],[12],[1,\"\\n            \"],[10,1],[14,0,\"absolute bottom-12 w-full text-center left-0 lowercase\"],[12],[1,\"\\n              \"],[1,[28,[35,4],[[52,[28,[37,9],[[30,1],1],null],\"⇧ \",\"\"],[30,3,[\"letter\"]]],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[3]],null],[1,\"      \"],[13],[1,\"\\n\"]],[2]],null]],[1]],null],[13],[1,\"\\n\"]],[\"octave\",\"keys\",\"key\",\"@currentNotes\",\"@onMouseDown\",\"@onMouseUp\"],false,[\"local-class\",\"each\",\"-track-array\",\"array\",\"concat\",\"if\",\"compute\",\"fn\",\"on\",\"eq\"]]",
    "moduleName": "music-machine/components/synth/keyboard.hbs",
    "isStrictMode": false
  });

  const NOTES_KEYS_MAP = {
    C: 'a',
    'C#': 'w',
    D: 's',
    'D#': 'e',
    E: 'd',
    F: 'f',
    'F#': 't',
    G: 'g',
    'G#': 'y',
    A: 'h',
    'A#': 'u',
    B: 'j'
  };
  let SynthKnobComponent = (_class = class SynthKnobComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "groupedKeys", _descriptor, this);

      this.createKeys();
    }

    createKeys() {
      const notes = Object.keys(_notes.default[1]);
      const keys = notes.map(note => {
        return {
          cssClass: note.length === 1 ? 'white-key' : 'black-key',
          letter: NOTES_KEYS_MAP[note],
          note
        };
      });
      const groupedKeys = [];
      keys.forEach((key, index) => {
        if (key.note.length === 1) {
          const group = [key];

          if (notes.includes(`${key.note}#`)) {
            group.push(keys[index + 1]);
          }

          groupedKeys.push(group);
        }
      });
      this.groupedKeys = groupedKeys;
    }

    isKeyPlayed(key, octaveOffset, currentNotes) {
      const octave = this.args.currentOctave + octaveOffset;
      const note = `${octave} ${key.note}`;
      return !!currentNotes[note];
    }

    handleMouseOver(key, octave, event) {
      if (event.buttons === 1) {
        this.args.onMouseDown(key, octave);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "groupedKeys", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "isKeyPlayed", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "isKeyPlayed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleMouseOver", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseOver"), _class.prototype)), _class);
  _exports.default = SynthKnobComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SynthKnobComponent);
});