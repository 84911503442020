define("music-machine/utils/synth/effects/compressor", ["exports", "music-machine/utils/synth/base-node"], function (_exports, _baseNode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CompressorSettings = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CompressorSettings {
    constructor() {
      _defineProperty(this, "threshold", -50);

      _defineProperty(this, "knee", 40);

      _defineProperty(this, "ratio", 12);

      _defineProperty(this, "attack", 0);

      _defineProperty(this, "release", 0.25);
    }

  }

  _exports.CompressorSettings = CompressorSettings;

  class Compressor extends _baseNode.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "audioContext", void 0);

      _defineProperty(this, "compressor", void 0);

      _defineProperty(this, "threshold", -50);

      _defineProperty(this, "knee", 40);

      _defineProperty(this, "ratio", 12);

      _defineProperty(this, "attack", 0);

      _defineProperty(this, "release", 0.25);

      _defineProperty(this, "_on", false);
    }

    initialize(audioContext) {
      super.initialize(audioContext);
      this.compressor = this.audioContext.createDynamicsCompressor();
      this.input.connect(this.compressor);
      this.compressor.connect(this.output);
      this.apply({
        threshold: this.threshold,
        knee: this.knee,
        ratio: this.ratio,
        attack: this.attack,
        release: this.release
      });
    }

    apply(settings) {
      this.threshold = settings.threshold;
      this.knee = settings.knee;
      this.ratio = settings.ratio;
      this.attack = settings.attack;
      this.release = settings.release;

      if (this.compressor) {
        this.compressor.threshold.value = settings.threshold;
        this.compressor.knee.value = settings.knee;
        this.compressor.ratio.value = settings.ratio;
        this.compressor.attack.value = settings.attack;
        this.compressor.release.value = settings.release;
      }
    }

  }

  _exports.default = Compressor;
});