define("music-machine/utils/synth/oscillator", ["exports", "music-machine/utils/synth/effects/biquad-filter", "music-machine/utils/synth/envelope"], function (_exports, _biquadFilter, _envelope) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.OscillatorSettings = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class OscillatorSettings {
    constructor() {
      _defineProperty(this, "waveForm", 'sine');

      _defineProperty(this, "frequency", 440);

      _defineProperty(this, "gain", 0.5);

      _defineProperty(this, "pitch", 0);

      _defineProperty(this, "detune", 0);

      _defineProperty(this, "octaveOffset", 0);

      _defineProperty(this, "highpass", _biquadFilter.MIN_AMOUNT);

      _defineProperty(this, "lowpass", _biquadFilter.MAX_AMOUNT);

      _defineProperty(this, "envelope", new _envelope.EnvelopeSettings());
    }

  }

  _exports.OscillatorSettings = OscillatorSettings;

  class Oscillator {
    set waveForm(waveForm) {
      this.oscillator.type = waveForm;
    }

    get waveForm() {
      return this.oscillator?.type || this.settings.waveForm;
    }

    constructor() {
      let settings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;

      _defineProperty(this, "audioContext", null);

      _defineProperty(this, "settings", void 0);

      _defineProperty(this, "frequency", void 0);

      _defineProperty(this, "pitch", void 0);

      _defineProperty(this, "oscillator", void 0);

      _defineProperty(this, "highpass", void 0);

      _defineProperty(this, "lowpass", void 0);

      _defineProperty(this, "envelope", void 0);

      _defineProperty(this, "octaveOffset", 0);

      _defineProperty(this, "_detune", 0);

      _defineProperty(this, "gain", void 0);

      _defineProperty(this, "effects", []);

      if (settings) {
        this.settings = settings;
      } else {
        this.settings = new OscillatorSettings();
      }

      this.highpass = new _biquadFilter.default('highpass');
      this.lowpass = new _biquadFilter.default('lowpass');
    }

    set detune(value) {
      if (value !== this.detune) {
        this._detune = value;
        this.oscillator.detune.setValueAtTime(this._detune, this.audioContext.currentTime);
      }
    }

    get detune() {
      return this._detune;
    }

    initialize(audioContext) {
      this.audioContext = audioContext;
      this.highpass.initialize(audioContext);
      this.lowpass.initialize(audioContext);
      this.gain = this.audioContext.createGain();
      this.effectGain = this.audioContext.createGain();
      this.envelopeGain = this.audioContext.createGain();
      this.envelopeGain.connect(this.lowpass.input);
      this.lowpass.connect(this.highpass.input);
      this.highpass.connect(this.effectGain);
      this.effectGain.connect(this.gain);
      this.oscillator = this.audioContext.createOscillator();
      this.oscillator.connect(this.envelopeGain);
      this.apply(this.settings);
      this.envelope.initialize(this.audioContext);
      this.envelope.connect(this.envelopeGain.gain);
      this.oscillator.start();
    }

    apply(settings) {
      this.settings = settings;
      this.waveForm = settings.waveForm;
      this.frequency = settings.frequency;
      this.pitch = settings.pitch;
      this.octaveOffset = settings.octaveOffset;
      this.gain.gain.value = settings.gain;
      this.detune = settings.detune;

      if (!this.envelope) {
        this.envelope = new _envelope.default(settings.envelope);
      } else {
        this.envelope.apply(settings.envelope);
      }

      if (this.highpass) {
        this.highpass.apply({
          amount: settings.highpass
        });
      }

      if (this.lowpass) {
        this.lowpass.apply({
          amount: settings.lowpass
        });
      }
    }

    nodeForTarget(target) {
      switch (target) {
        case 'pitch':
          return this.oscillator.detune;

        case 'volume':
          return this.effectGain.gain;

        case 'lowpass':
          return this.lowpass.frequency;

        case 'highpass':
          return this.highpass.frequency;
      }

      return null;
    }

    valueForTarget(target) {
      switch (target) {
        case 'pitch':
          return this.detune;

        case 'volume':
          return 1.0;

        case 'lowpass':
          return this.settings.lowpass;

        case 'highpass':
          return this.settings.highpass;
      }

      return null;
    }

    addEffect(effect, target) {
      if (!this.audioContext) {
        return;
      }

      this.effects.push(effect);
      effect.connect(this.nodeForTarget(target));
    }

    removeEffect(effect, target) {
      const index = this.effects.indexOf(effect);

      if (index < 0) {
        return;
      }

      this.effects.splice(index, 1);
      effect.disconnect(this.nodeForTarget(target));
    }

    triggerEffects(time) {
      this.effects.forEach(effect => {
        effect.trigger?.(this, time);
      });
    }

    connect(param) {
      this.gain.connect(param);
    }

    play(freq) {
      this.frequency = freq;
      const now = this.audioContext.currentTime;
      this.oscillator.frequency.setValueAtTime(freq, now);
      this.envelope.start();
      this.triggerEffects(now);
    }

    stop() {
      this.envelope.stop();
    }

  }

  _exports.default = Oscillator;
});