define("music-machine/components/synth/effects/distortion", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "music-machine/utils/synth/effects/distortion"], function (_exports, _component, _templateFactory, _component2, _distortion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="relative flex items-center px-12 pt-32 pb-20">
    <label class="absolute top-12 body-text-s text-base-grey-78 lowercase">
      {{"distortion "}}
    </label>
    <Synth::Knob
      @label="amount"
      @minValue={{this.limits.min}}
      @maxValue={{this.limits.max}}
      @value={{@settings.distortion}}
      @onChange={{fn (mut @settings.distortion)}}
    />
  </div>
  
  */
  {
    "id": "nGrUsYS8",
    "block": "[[[10,0],[14,0,\"relative flex items-center px-12 pt-32 pb-20\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"absolute top-12 body-text-s text-base-grey-78 lowercase\"],[12],[1,\"\\n    \"],[1,\"distortion \"],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,0],null,[[\"@label\",\"@minValue\",\"@maxValue\",\"@value\",\"@onChange\"],[\"amount\",[30,0,[\"limits\",\"min\"]],[30,0,[\"limits\",\"max\"]],[30,1,[\"distortion\"]],[28,[37,1],[[28,[37,2],[[30,1,[\"distortion\"]]],null]],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@settings\"],false,[\"synth/knob\",\"fn\",\"mut\"]]",
    "moduleName": "music-machine/components/synth/effects/distortion.hbs",
    "isStrictMode": false
  });

  class DistortionComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "limits", _distortion.LIMITS);
    }

  }

  _exports.default = DistortionComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DistortionComponent);
});