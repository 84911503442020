define("music-machine/components/synth/label.module.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "label": "label__label_hniejo"
  };
  _exports.default = _default;
});