define("music-machine/components/synth/keyboard.module.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "keyboard": "keyboard__keyboard_1c4y1m",
    "key": "keyboard__key_1c4y1m",
    "white-key": "keyboard__white-key_1c4y1m",
    "white-key--active": "keyboard__white-key--active_1c4y1m",
    "black-key": "keyboard__black-key_1c4y1m",
    "black-key--active": "keyboard__black-key--active_1c4y1m"
  };
  _exports.default = _default;
});