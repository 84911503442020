define("music-machine/utils/synth/effects/delay", ["exports", "music-machine/utils/synth/base-node"], function (_exports, _baseNode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TIME_LIMITS = _exports.MIN_TIME = _exports.MIN_AMOUNT = _exports.MAX_TIME = _exports.MAX_AMOUNT = _exports.DelaySettings = _exports.AMOUNT_LIMITS = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const MIN_TIME = 0;
  _exports.MIN_TIME = MIN_TIME;
  const MIN_AMOUNT = 0;
  _exports.MIN_AMOUNT = MIN_AMOUNT;
  const MAX_TIME = 1.0;
  _exports.MAX_TIME = MAX_TIME;
  const MAX_AMOUNT = 0.5;
  _exports.MAX_AMOUNT = MAX_AMOUNT;
  const TIME_LIMITS = {
    min: MIN_TIME,
    max: MAX_TIME
  };
  _exports.TIME_LIMITS = TIME_LIMITS;
  const AMOUNT_LIMITS = {
    min: MIN_AMOUNT,
    max: MAX_AMOUNT
  };
  _exports.AMOUNT_LIMITS = AMOUNT_LIMITS;

  class DelaySettings {
    constructor() {
      _defineProperty(this, "amount", 0.0);

      _defineProperty(this, "time", 0.0);

      _defineProperty(this, "on", false);
    }

  }

  _exports.DelaySettings = DelaySettings;

  class Delay extends _baseNode.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "audioContext", void 0);

      _defineProperty(this, "delay", void 0);

      _defineProperty(this, "wetLevel", void 0);

      _defineProperty(this, "feedback", void 0);

      _defineProperty(this, "amount", MIN_AMOUNT);

      _defineProperty(this, "time", MIN_TIME);

      _defineProperty(this, "_on", false);

      _defineProperty(this, "_threshold", void 0);
    }

    set on(on) {
      if (on === this._on) {
        return;
      }

      this._on = on;

      if (on) {
        this.input.connect(this.delay);
        this.wetLevel.connect(this.output);
      } else {
        this.input.disconnect(this.delay);
        this.wetLevel.disconnect(this.output);
      }
    }

    get on() {
      return this._on;
    }

    initialize(audioContext) {
      super.initialize(audioContext);
      this.delay = this.audioContext.createDelay();
      this.feedback = this.audioContext.createGain();
      this.wetLevel = this.audioContext.createGain();
      this.delay.connect(this.feedback);
      this.delay.connect(this.wetLevel);
      this.feedback.connect(this.delay);
      this.input.connect(this.output);
      this.apply({
        amount: this.amount,
        time: this.time,
        on: this.on
      });
    }

    apply(settings) {
      this.amount = settings.amount;
      this.time = settings.time;
      this.on = settings.on;

      if (settings.on && this.delay && this.feedback && this.wetLevel) {
        this.delay.delayTime.value = this.time;
        this.feedback.gain.value = this.amount * 0.5;
        this.wetLevel.gain.value = this.amount;
      }
    }

  }

  _exports.default = Delay;
});