define("music-machine/components/synth/sequencer/step", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    type="button"
    class="grow h-40 overflow-hidden"
    local-class={{class-names
      "step"
      (unless @step.enabled "step--disabled")
      (if (and @active @step.enabled) "step--active")
      (if (and @active @isRecordMode) "step--active-record")
    }}
    {{on "click" @onClick}}
  >
    <span local-class="inner"></span>
  </button>
  
  */
  {
    "id": "G5nDuk1Y",
    "block": "[[[11,\"button\"],[16,0,[29,[\"grow h-40 overflow-hidden \",[28,[37,0],[[28,[37,1],[\"step\",[52,[51,[30,1,[\"enabled\"]]],\"step--disabled\"],[52,[28,[37,4],[[30,2],[30,1,[\"enabled\"]]],null],\"step--active\"],[52,[28,[37,4],[[30,2],[30,3]],null],\"step--active-record\"]],null]],[[\"from\"],[\"music-machine/components/synth/sequencer/step.module.scss\"]]]]]],[24,4,\"button\"],[4,[38,5],[\"click\",[30,4]],null],[12],[1,\"\\n  \"],[10,1],[15,0,[29,[[28,[37,0],[\"inner\"],[[\"from\"],[\"music-machine/components/synth/sequencer/step.module.scss\"]]]]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@step\",\"@active\",\"@isRecordMode\",\"@onClick\"],false,[\"local-class\",\"class-names\",\"unless\",\"if\",\"and\",\"on\"]]",
    "moduleName": "music-machine/components/synth/sequencer/step.hbs",
    "isStrictMode": false
  });

  let SynthKnobComponent = (_class = class SynthKnobComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "notes", null);
    }

    get step() {
      return this.args.step;
    }

    get noteValue() {
      return this.notes.indexOf(this.step.note);
    }

    handleOctaveChange(value) {
      this.step.octave = Math.round(value);
    }

    handleNoteChange(value) {
      this.step.note = this.notes[Math.round(value)];
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleOctaveChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOctaveChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleNoteChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleNoteChange"), _class.prototype)), _class);
  _exports.default = SynthKnobComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SynthKnobComponent);
});