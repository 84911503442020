define("music-machine/components/instructions.module.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "instructions__content_489qou"
  };
  _exports.default = _default;
});