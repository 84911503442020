define("music-machine/components/synth/switch/index.module.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "range": "index__range_18wgdk",
    "options": "index__options_18wgdk",
    "option": "index__option_18wgdk"
  };
  _exports.default = _default;
});