define("music-machine/components/synth/knob", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/template", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _template, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <div class="flex flex-col align-center items-center relative py-12">
      <div class="relative inline-flex p-12">
        {{#each this.knobTicks as |tick|}}
          <span
            local-class="tick tick--t{{tick}} {{if
              (includes tick (array 5 7))
              "tick--main"
            }}"
          ></span>
        {{/each}}
  
        <div local-class="knob">
          {{! template-lint-disable no-down-event-binding }}
          <button
            type="button"
            local-class="knob-inner knob-inner--{{@color}}"
            style={{this.knobStyle}}
            {{on "mousedown" this.handleMouseDown}}
          >
            <span local-class="indicator"></span>
          </button>
          {{! template-lint-enable no-down-event-binding }}
        </div>
      </div>
      <Synth::Label @label={{@label}} />
    </div>
  </div>
  
  */
  {
    "id": "3ewUMAT3",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col align-center items-center relative py-12\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"relative inline-flex p-12\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"knobTicks\"]]],null]],null],null,[[[1,\"        \"],[10,1],[15,0,[29,[[28,[37,2],[[28,[37,3],[\"tick tick--t\",[30,2],\" \",[52,[28,[37,5],[[30,2],[28,[37,6],[5,7],null]],null],\"tick--main\"]],null]],[[\"from\"],[\"music-machine/components/synth/knob.module.scss\"]]]]]],[12],[13],[1,\"\\n\"]],[2]],null],[1,\"\\n      \"],[10,0],[15,0,[29,[[28,[37,2],[\"knob\"],[[\"from\"],[\"music-machine/components/synth/knob.module.scss\"]]]]]],[12],[1,\"\\n\"],[1,\"        \"],[11,\"button\"],[16,0,[29,[[28,[37,2],[[28,[37,3],[\"knob-inner knob-inner--\",[30,3]],null]],[[\"from\"],[\"music-machine/components/synth/knob.module.scss\"]]]]]],[16,5,[30,0,[\"knobStyle\"]]],[24,4,\"button\"],[4,[38,7],[\"mousedown\",[30,0,[\"handleMouseDown\"]]],null],[12],[1,\"\\n          \"],[10,1],[15,0,[29,[[28,[37,2],[\"indicator\"],[[\"from\"],[\"music-machine/components/synth/knob.module.scss\"]]]]]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,8],null,[[\"@label\"],[[30,4]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"tick\",\"@color\",\"@label\"],false,[\"each\",\"-track-array\",\"local-class\",\"concat\",\"if\",\"includes\",\"array\",\"on\",\"synth/label\"]]",
    "moduleName": "music-machine/components/synth/knob.hbs",
    "isStrictMode": false
  });

  const MIN_ANGLE = 30;
  const MAX_ANGLE = 330;
  const MAX_MIN_DIFF = MAX_ANGLE - MIN_ANGLE;
  const TUNE_MODIFIER = 0.18;
  const FINE_TUNE_MODIFIER = 0.18;

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  let SynthKnobComponent = (_dec = (0, _service.inject)('settings-change'), (_class = class SynthKnobComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "settingsChange", _descriptor, this);

      _initializerDefineProperty(this, "valuePercentage", _descriptor2, this);

      _defineProperty(this, "startingDragPoint", {});

      _defineProperty(this, "previousDragPoint", {});

      _defineProperty(this, "valueAtStartDrag", 0);

      _defineProperty(this, "handleMouseUp", () => {
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
      });

      _defineProperty(this, "handleMouseMove", event => {
        const {
          clientY,
          clientX
        } = event;
        const startDiffX = Math.abs(this.startingDragPoint.x - clientX);
        const startDiffY = Math.abs(this.startingDragPoint.y - clientY);
        const isDraggingVertically = startDiffY > startDiffX;
        const diffX = Math.abs(this.previousDragPoint.x - clientX);
        const diffY = Math.abs(this.previousDragPoint.y - clientY);
        const isPositive = isDraggingVertically ? clientY < this.previousDragPoint.y : clientX > this.previousDragPoint.x;
        const diff = isDraggingVertically ? diffY * TUNE_MODIFIER : diffX * FINE_TUNE_MODIFIER;
        this.valuePercentage = clamp(this.valuePercentage + (isPositive ? diff : -diff), 0, 100);
        let value = this.valuePercentage * this.range * 0.01;

        if (this.args.snap) {
          value = Math.round(value / this.args.snap) * this.args.snap;
        }

        this.args.onChange(this.minValue + value);
        this.settingsChange.trigger();
        this.previousDragPoint = {
          x: clientX,
          y: clientY
        };
      });

      this.valuePercentage = (this.value - this.minValue) / this.range * 100;
    }

    get indicatorAngle() {
      const percentage = (this.value - this.minValue) / this.range;
      return MIN_ANGLE + MAX_MIN_DIFF * percentage;
    }

    get range() {
      return this.maxValue - this.minValue;
    }

    get knobTicks() {
      return [0, 1, 2, 3, 4, 5, 7, 8, 9, 10, 11];
    }

    get value() {
      return this.args.value || 0;
    }

    get minValue() {
      return this.args.minValue || 0;
    }

    get maxValue() {
      return this.args.maxValue || 10;
    }

    get knobStyle() {
      return (0, _template.htmlSafe)('transform: rotate(' + this.indicatorAngle + 'deg)');
    }

    handleMouseDown(event) {
      const {
        clientY,
        clientX
      } = event;
      this.startingDragPoint = {
        x: clientX,
        y: clientY
      };
      this.previousDragPoint = {
        x: clientX,
        y: clientY
      };
      this.valueAtStartDrag = this.valuePercentage;
      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.handleMouseUp);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settingsChange", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "valuePercentage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleMouseDown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseDown"), _class.prototype)), _class));
  _exports.default = SynthKnobComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SynthKnobComponent);
});