define("music-machine/components/synth/instrument-panel.module.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "root": "instrument-panel__root_1lpp01",
    "input": "instrument-panel__input_1lpp01",
    "button": "instrument-panel__button_1lpp01",
    "small-button": "instrument-panel__small-button_1lpp01",
    "mini-button": "instrument-panel__mini-button_1lpp01"
  };
  _exports.default = _default;
});