define("music-machine/components/synth/instrument", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "music-machine/utils/synth/effects/distortion", "music-machine/utils/synth/effects/biquad-filter", "music-machine/utils/synth/instrument"], function (_exports, _component, _templateFactory, _component2, _tracking, _distortion, _biquadFilter, _instrument) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes class="border-brand-primary-10">
    <h2>{{@settings.name}}</h2>
    {{#each @settings.oscillators as |oscillator index|}}
      <Synth::Oscillator @index={{index}} @oscillator={{oscillator}} />
    {{/each}}
    <Synth::Knob
      @label={{"Voices"}}
      @snap={{1}}
      @minValue={{1}}
      @maxValue={{this.maxVoices}}
      @value={{@settings.voices}}
      @onChange={{fn (mut @settings.voices)}}
    />
    <div>
      <h3>{{"filters"}}</h3>
      <Synth::Knob
        @label="highpass"
        @minValue={{this.biquadFilterLimits.min}}
        @maxValue={{this.biquadFilterLimits.max}}
        @value={{@settings.highpass}}
        @onChange={{fn (mut @settings.highpass)}}
      />
      <Synth::Knob
        @label="lowpass"
        @minValue={{this.biquadFilterLimits.min}}
        @maxValue={{this.biquadFilterLimits.max}}
        @value={{@settings.lowpass}}
        @onChange={{fn (mut @settings.lowpass)}}
      />
      <Synth::Knob
        @label="distortion"
        @minValue={{this.distortionFilterLimits.min}}
        @maxValue={{this.distortionFilterLimits.max}}
        @value={{@settings.distortion}}
        @onChange={{fn (mut @settings.distortion)}}
      />
    </div>
  </div>
  
  */
  {
    "id": "I8e3yl3c",
    "block": "[[[11,0],[17,1],[24,0,\"border-brand-primary-10\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[30,2,[\"name\"]]],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2,[\"oscillators\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@index\",\"@oscillator\"],[[30,4],[30,3]]],null],[1,\"\\n\"]],[3,4]],null],[1,\"  \"],[8,[39,3],null,[[\"@label\",\"@snap\",\"@minValue\",\"@maxValue\",\"@value\",\"@onChange\"],[\"Voices\",1,1,[30,0,[\"maxVoices\"]],[30,2,[\"voices\"]],[28,[37,4],[[28,[37,5],[[30,2,[\"voices\"]]],null]],null]]],null],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,\"filters\"],[13],[1,\"\\n    \"],[8,[39,3],null,[[\"@label\",\"@minValue\",\"@maxValue\",\"@value\",\"@onChange\"],[\"highpass\",[30,0,[\"biquadFilterLimits\",\"min\"]],[30,0,[\"biquadFilterLimits\",\"max\"]],[30,2,[\"highpass\"]],[28,[37,4],[[28,[37,5],[[30,2,[\"highpass\"]]],null]],null]]],null],[1,\"\\n    \"],[8,[39,3],null,[[\"@label\",\"@minValue\",\"@maxValue\",\"@value\",\"@onChange\"],[\"lowpass\",[30,0,[\"biquadFilterLimits\",\"min\"]],[30,0,[\"biquadFilterLimits\",\"max\"]],[30,2,[\"lowpass\"]],[28,[37,4],[[28,[37,5],[[30,2,[\"lowpass\"]]],null]],null]]],null],[1,\"\\n    \"],[8,[39,3],null,[[\"@label\",\"@minValue\",\"@maxValue\",\"@value\",\"@onChange\"],[\"distortion\",[30,0,[\"distortionFilterLimits\",\"min\"]],[30,0,[\"distortionFilterLimits\",\"max\"]],[30,2,[\"distortion\"]],[28,[37,4],[[28,[37,5],[[30,2,[\"distortion\"]]],null]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@settings\",\"oscillator\",\"index\"],false,[\"each\",\"-track-array\",\"synth/oscillator\",\"synth/knob\",\"fn\",\"mut\"]]",
    "moduleName": "music-machine/components/synth/instrument.hbs",
    "isStrictMode": false
  });

  let InstrumentComponent = (_class = class InstrumentComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "oscillators", _descriptor, this);

      _initializerDefineProperty(this, "voices", _descriptor2, this);

      _defineProperty(this, "maxVoices", _instrument.MAX_VOICES);

      _defineProperty(this, "biquadFilterLimits", _biquadFilter.LIMITS);

      _defineProperty(this, "distortionFilterLimits", _distortion.LIMITS);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "oscillators", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "voices", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  })), _class);
  _exports.default = InstrumentComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, InstrumentComponent);
});