define("music-machine/utils/synth/effects/envelope", ["exports", "music-machine/utils/synth/base-node", "music-machine/utils/synth/effects/biquad-filter"], function (_exports, _baseNode, _biquadFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RELEASE_LIMITS = _exports.MIN_RELEASE = _exports.MIN_INT = _exports.MIN_ATTACK = _exports.MAX_RELEASE = _exports.MAX_INT = _exports.MAX_ATTACK = _exports.INT_MULTIPLIERS = _exports.INT_LIMITS = _exports.EnvelopeSettings = _exports.ATTACK_LIMITS = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const MIN_ATTACK = 0;
  _exports.MIN_ATTACK = MIN_ATTACK;
  const MIN_RELEASE = 0.0;
  _exports.MIN_RELEASE = MIN_RELEASE;
  const MIN_INT = -1.0;
  _exports.MIN_INT = MIN_INT;
  const MAX_ATTACK = 5;
  _exports.MAX_ATTACK = MAX_ATTACK;
  const MAX_RELEASE = 5;
  _exports.MAX_RELEASE = MAX_RELEASE;
  const MAX_INT = 1.0;
  _exports.MAX_INT = MAX_INT;
  const ATTACK_LIMITS = {
    min: MIN_ATTACK,
    max: MAX_ATTACK
  };
  _exports.ATTACK_LIMITS = ATTACK_LIMITS;
  const RELEASE_LIMITS = {
    min: MIN_RELEASE,
    max: MAX_RELEASE
  };
  _exports.RELEASE_LIMITS = RELEASE_LIMITS;
  const INT_LIMITS = {
    min: MIN_INT,
    max: MAX_INT
  };
  _exports.INT_LIMITS = INT_LIMITS;
  const QUARTER_BIQUAD_MAX_AMOUNT = _biquadFilter.MAX_AMOUNT / 4;
  const INT_MULTIPLIERS = {
    pitch: 1000,
    highpass: QUARTER_BIQUAD_MAX_AMOUNT,
    lowpass: QUARTER_BIQUAD_MAX_AMOUNT
  };
  _exports.INT_MULTIPLIERS = INT_MULTIPLIERS;

  class EnvelopeSettings {
    constructor() {
      _defineProperty(this, "attack", 0.05);

      _defineProperty(this, "release", 0.1);

      _defineProperty(this, "int", 0);

      _defineProperty(this, "target", 'pitch');
    }

  }

  _exports.EnvelopeSettings = EnvelopeSettings;

  class Envelope extends _baseNode.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "gain", void 0);

      _defineProperty(this, "oscillatorIndex", void 0);

      _defineProperty(this, "attack", 0.05);

      _defineProperty(this, "release", 0.1);

      _defineProperty(this, "int", 0);

      _defineProperty(this, "target", 'pitch');

      _defineProperty(this, "_on", false);

      _defineProperty(this, "params", []);
    }

    set on(on) {
      if (on === this._on) {
        return;
      }

      this._on = on;
    }

    get on() {
      return this._on;
    }

    initialize(audioContext) {
      super.initialize(audioContext);
      this.gain = this.audioContext.createGain();
    }

    apply(settings) {
      this.attack = settings.attack;
      this.release = settings.release;
      this.int = settings.int;
      this.target = settings.target;
      this.on = settings.on;
    }

    connect(param) {
      this.params.push(param);
    }

    disconnect(param) {
      const index = this.params.indexOf(param);

      if (index > -1) {
        this.params.splice(index, 1);
      }
    }

    trigger(oscillator, time) {
      if (this.params.length === 0 || !this.on) {
        return;
      }

      const param = oscillator.nodeForTarget(this.target);
      const value = oscillator.valueForTarget(this.target);
      param.cancelScheduledValues(time);
      param.setValueAtTime(value, time);
      param.linearRampToValueAtTime(value + INT_MULTIPLIERS[this.target] * this.int, time + this.attack);
      param.linearRampToValueAtTime(value, time + this.attack + this.release);
    }

  }

  _exports.default = Envelope;
});