define("music-machine/components/synth/switch/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="relative pl-24 pr-12 flex items-center py-16">
    <label>
      <input
        local-class="range"
        type="range"
        value={{this.value}}
        min="0"
        max={{dec @options.length}}
        class="rotate-90 absolute top-1/2 left-12 -translate-x-1/2 -translate-y-1/2"
        {{on "input" this.handleChange}}
      />
    </label>
    <div local-class="options" class="pl-4 py-2 flex flex-col justify-between">
      {{#each @options as |option|}}
        <div local-class="option" class="leading-s lowercase">
          {{yield option}}
        </div>
      {{/each}}
    </div>
    <Synth::Label @label={{@label}} class="w-auto left-12 -translate-x-1/2" />
  </div>
  */
  {
    "id": "JNqTPXQ5",
    "block": "[[[10,0],[14,0,\"relative pl-24 pr-12 flex items-center py-16\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    \"],[11,\"input\"],[16,0,[29,[\"rotate-90 absolute top-1/2 left-12 -translate-x-1/2 -translate-y-1/2 \",[28,[37,0],[\"range\"],[[\"from\"],[\"music-machine/components/synth/switch/index.module.scss\"]]]]]],[16,2,[30,0,[\"value\"]]],[24,\"min\",\"0\"],[16,\"max\",[28,[37,1],[[30,1,[\"length\"]]],null]],[24,4,\"range\"],[4,[38,2],[\"input\",[30,0,[\"handleChange\"]]],null],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"pl-4 py-2 flex flex-col justify-between \",[28,[37,0],[\"options\"],[[\"from\"],[\"music-machine/components/synth/switch/index.module.scss\"]]]]]],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1]],null]],null],null,[[[1,\"      \"],[10,0],[15,0,[29,[\"leading-s lowercase \",[28,[37,0],[\"option\"],[[\"from\"],[\"music-machine/components/synth/switch/index.module.scss\"]]]]]],[12],[1,\"\\n        \"],[18,4,[[30,2]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n  \"],[8,[39,6],[[24,0,\"w-auto left-12 -translate-x-1/2\"]],[[\"@label\"],[[30,3]]],null],[1,\"\\n\"],[13]],[\"@options\",\"option\",\"@label\",\"&default\"],false,[\"local-class\",\"dec\",\"on\",\"each\",\"-track-array\",\"yield\",\"synth/label\"]]",
    "moduleName": "music-machine/components/synth/switch/index.hbs",
    "isStrictMode": false
  });

  let SynthSwitchComponent = (_dec = (0, _service.inject)('settings-change'), (_class = class SynthSwitchComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "settingsChange", _descriptor, this);
    }

    get value() {
      return this.args.options.indexOf(this.args.value);
    }

    handleChange(e) {
      this.args.onChange(this.args.options[parseInt(e.target.value)]);
      this.settingsChange.trigger();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settingsChange", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class));
  _exports.default = SynthSwitchComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SynthSwitchComponent);
});