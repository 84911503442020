define("music-machine/utils/synth/instrument", ["exports", "music-machine/utils/synth/voice", "music-machine/utils/synth/effects/envelope", "music-machine/utils/synth/oscillator", "music-machine/utils/synth/effects/distortion", "music-machine/utils/synth/effects/delay", "music-machine/utils/synth/effects/compressor", "music-machine/utils/synth/effects/lfo"], function (_exports, _voice, _envelope, _oscillator, _distortion, _delay, _compressor, _lfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MAX_VOICES = _exports.InstrumentSettings = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const MAX_VOICES = 8;
  _exports.MAX_VOICES = MAX_VOICES;

  class InstrumentSettings {
    constructor() {
      _defineProperty(this, "name", '');

      _defineProperty(this, "voices", 4);

      _defineProperty(this, "delayAmount", _delay.MIN_AMOUNT);

      _defineProperty(this, "delayTime", _delay.MIN_TIME);

      _defineProperty(this, "delayOn", false);

      _defineProperty(this, "lfoRate", _lfo.MIN_RATE);

      _defineProperty(this, "lfoInt", _lfo.MIN_INT);

      _defineProperty(this, "lfoTarget", 'pitch');

      _defineProperty(this, "lfoWaveForm", 'sine');

      _defineProperty(this, "lfoOscillator", void 0);

      _defineProperty(this, "envelopeAttack", 0.05);

      _defineProperty(this, "envelopeRelease", 0.04);

      _defineProperty(this, "envelopeTarget", 'pitch');

      _defineProperty(this, "envelopeInt", 0.0);

      _defineProperty(this, "gain", 0.5);

      _defineProperty(this, "distortion", _distortion.MIN_AMOUNT);

      _defineProperty(this, "oscillators", [new _oscillator.OscillatorSettings(), new _oscillator.OscillatorSettings(), new _oscillator.OscillatorSettings()]);
    }

  }

  _exports.InstrumentSettings = InstrumentSettings;

  class Instrument {
    constructor() {
      _defineProperty(this, "audioContext", void 0);

      _defineProperty(this, "oscillators", []);

      _defineProperty(this, "gain", void 0);

      _defineProperty(this, "distortionEffect", void 0);

      _defineProperty(this, "lowpassFilter", void 0);

      _defineProperty(this, "highpassFilter", void 0);

      _defineProperty(this, "delayEffect", void 0);

      _defineProperty(this, "lfo", void 0);

      _defineProperty(this, "lfoTarget", 'detune');

      _defineProperty(this, "lfoOscillator", void 0);

      _defineProperty(this, "_voices", []);

      this.lfo = new _lfo.default();
      this.distortionEffect = new _distortion.default();
      this.delayEffect = new _delay.default();
      this.envelopeFilter = new _envelope.default();
      this.limiter = new _compressor.default();
      this.limiter.apply({
        threshold: -5,
        knee: 0,
        ratio: 40,
        attack: 0.001,
        release: 0.1
      });
      this.applyToOscillators(new InstrumentSettings());
    }

    get settings() {
      const that = this;
      const handler = {
        set(target, prop, value) {
          target[prop] = value;
          that.apply(target);
          return true;
        }

      };
      return new Proxy({
        name: this.name,
        gain: this?.gain?.gain?.value || 0.33,
        delayOn: this.delayEffect.on,
        delayAmount: this.delayEffect.amount,
        delayTime: this.delayEffect.time,
        lfoOn: this.lfo.on,
        lfoRate: this.lfo.rate,
        lfoInt: this.lfo.int,
        lfoTarget: this.lfo.target,
        lfoWaveForm: this.lfo.waveForm,
        lfoOscillator: !this.lfo.oscillatorIndex ? 0 : this.lfo.oscillatorIndex,
        envelopeOn: this.envelopeFilter.on,
        envelopeRelease: this.envelopeFilter.release,
        envelopeAttack: this.envelopeFilter.attack,
        envelopeTarget: this.envelopeFilter.target,
        envelopeInt: this.envelopeFilter.int,
        envelopeOscillator: !this.envelopeFilter.oscillatorIndex ? 0 : this.envelopeFilter.oscillatorIndex,
        distortion: this.distortionEffect.amount,
        voices: this.voices,
        oscillators: this.oscillators
      }, handler);
    }

    get voices() {
      return this._voices.length;
    }

    set voices(voices) {
      if (voices < 1 || MAX_VOICES < voices) {
        return;
      }

      while (voices > this._voices.length) {
        const voice = new _voice.default(this.oscillators);

        if (this.audioContext) {
          voice.initialize(this.audioContext);
          voice.connect(this.gain);
        }

        this._voices.push(voice);
      }

      while (voices < this._voices.length) {
        const voice = this._voices.pop();

        if (voice) {
          voice.stop();
        }
      }
    }

    initialize(audioContext) {
      this.audioContext = audioContext;
      this.gain = audioContext.createGain();
      this.distortionEffect.initialize(audioContext);
      this.delayEffect.initialize(audioContext);
      this.limiter.initialize(audioContext);
      this.lfo.initialize(audioContext);
      this.envelopeFilter.initialize(audioContext);
      this.gain.connect(this.distortionEffect.input);
      this.distortionEffect.connect(this.delayEffect.input);
      this.delayEffect.connect(this.limiter.input);

      this._voices.forEach(voice => {
        voice.initialize(audioContext);
        voice.connect(this.gain);
      });
    }

    apply(settings) {
      this.name = settings.name;
      this.applyToOscillators(settings);
      this.voices = settings.voices;

      if (this.distortionEffect) {
        this.distortionEffect.apply({
          amount: settings.distortion
        });
      }

      if (this.lfo) {
        let hasChanged = settings.lfoTarget !== this.lfo.target || settings.lfoOscillator !== this.lfo.oscillatorIndex;
        this.lfo.apply({
          on: settings.lfoOn,
          rate: settings.lfoRate,
          int: settings.lfoInt,
          waveForm: settings.lfoWaveForm,
          target: settings.lfoTarget
        });

        if (hasChanged) {
          if (this.lfoOscillator !== undefined) {
            this.removeEffect(this.lfo, this.lfoOscillator);
          }

          this.lfo.oscillatorIndex = settings.lfoOscillator || 0;
          this.lfoOscillator = this.lfo.oscillatorIndex;
          this.addEffect(this.lfo, settings.lfoOscillator || 0, settings.lfoTarget);
        }
      }

      if (this.envelopeFilter) {
        let hasChanged = settings.envelopeTarget !== this.envelopeFilter.target || settings.envelopeOscillator !== this.envelopeFilter.oscillatorIndex;
        this.envelopeFilter.apply({
          on: settings.envelopeOn,
          release: settings.envelopeRelease,
          attack: settings.envelopeAttack,
          target: settings.envelopeTarget,
          int: settings.envelopeInt
        });

        if (hasChanged) {
          if (this.envelopeOscillator !== undefined) {
            this.removeEffect(this.envelopeFilter, this.envelopeOscillator);
          }

          this.envelopeFilter.oscillatorIndex = settings.envelopeOscillator || 0;
          this.envelopeOscillator = this.envelopeFilter.oscillatorIndex;
          this.addEffect(this.envelopeFilter, settings.envelopeOscillator || 0, settings.envelopeTarget);
        }
      }

      if (this.delayEffect) {
        this.delayEffect.apply({
          amount: settings.delayAmount,
          time: settings.delayTime,
          on: settings.delayOn
        });
      }

      if (this.gain) {
        this.gain.gain.value = parseFloat(settings.gain);
      }
    }

    applyToOscillators(settings) {
      const that = this;
      this.oscillators = settings.oscillators.map((osc, index) => {
        const oscHandler = {
          set(target, prop, value) {
            target[prop] = value;

            that._voices.forEach(voice => voice.oscillators[index].apply(target));

            return true;
          }

        };
        const envelopeHandler = {
          set(target, prop, value) {
            target[prop] = value;

            that._voices.forEach(voice => voice.oscillators[index].envelope.apply(target));

            return true;
          }

        };
        let obj = Object.assign({}, osc);
        obj.envelope = new Proxy(osc.envelope, envelopeHandler);
        return new Proxy(obj, oscHandler);
      });
    }

    play(note, octave) {
      this._voices = this._voices.sort((a, b) => a.startTime < b.startTime ? -1 : 1);

      let voice = this._voices.find(voice => !voice.isPlaying);

      if (!voice) {
        voice = this._voices[0];
      }

      return voice.play(note, octave);
    }

    addEffect(effect, oscillatorIndex, target) {
      this._voices.forEach(voice => {
        voice.addEffect(effect, oscillatorIndex, target);
      });
    }

    removeEffect(effect, oscillatorIndex, target) {
      this._voices.forEach(voice => {
        voice.removeEffect(effect, oscillatorIndex, target);
      });
    }

    connect(node) {
      this.limiter.connect(node);
    }

  }

  _exports.default = Instrument;
});