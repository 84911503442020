define("music-machine/components/synth/envelope", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex flex-row items-center space-x-8">
    <div class="flex flex-col items-center">
      <Synth::Knob
        @color="green"
        @label="Attack"
        @maxValue={{10}}
        @value={{@envelope.attack}}
        @onChange={{fn (mut @envelope.attack)}}
      />
    </div>
    <div class="flex flex-col items-center">
      <Synth::Knob
        @color="green"
        @label="Decay"
        @maxValue={{10}}
        @value={{@envelope.decay}}
        @onChange={{fn (mut @envelope.decay)}}
      />
    </div>
    <div class="flex flex-col items-center">
      <Synth::Knob
        @color="green"
        @label="Sustain"
        @maxValue={{1}}
        @value={{@envelope.sustain}}
        @onChange={{fn (mut @envelope.sustain)}}
      />
    </div>
    <div class="flex flex-col items-center">
      <Synth::Knob
        @color="green"
        @label="Release"
        @maxValue={{10}}
        @value={{@envelope.release}}
        @onChange={{fn (mut @envelope.release)}}
      />
    </div>
  </div>
  */
  {
    "id": "sIl3NJ3F",
    "block": "[[[10,0],[14,0,\"flex flex-row items-center space-x-8\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col items-center\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@color\",\"@label\",\"@maxValue\",\"@value\",\"@onChange\"],[\"green\",\"Attack\",10,[30,1,[\"attack\"]],[28,[37,1],[[28,[37,2],[[30,1,[\"attack\"]]],null]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col items-center\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@color\",\"@label\",\"@maxValue\",\"@value\",\"@onChange\"],[\"green\",\"Decay\",10,[30,1,[\"decay\"]],[28,[37,1],[[28,[37,2],[[30,1,[\"decay\"]]],null]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col items-center\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@color\",\"@label\",\"@maxValue\",\"@value\",\"@onChange\"],[\"green\",\"Sustain\",1,[30,1,[\"sustain\"]],[28,[37,1],[[28,[37,2],[[30,1,[\"sustain\"]]],null]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-col items-center\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@color\",\"@label\",\"@maxValue\",\"@value\",\"@onChange\"],[\"green\",\"Release\",10,[30,1,[\"release\"]],[28,[37,1],[[28,[37,2],[[30,1,[\"release\"]]],null]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@envelope\"],false,[\"synth/knob\",\"fn\",\"mut\"]]",
    "moduleName": "music-machine/components/synth/envelope.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});