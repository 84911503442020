define("music-machine/components/synth/effects/delay", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "music-machine/utils/synth/effects/delay"], function (_exports, _component, _templateFactory, _component2, _delay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="relative flex items-center px-12 pt-32 pb-20">
    <label class="absolute top-12 body-text-s text-base-grey-78 lowercase">
      {{"delay "}}
    </label>
  
    <Synth::Switch::OnOff
      @value={{@settings.delayOn}}
      @onChange={{fn (mut @settings.delayOn)}}
    />
  
    <Synth::Knob
      @label="time"
      @minValue={{this.timeLimits.min}}
      @maxValue={{this.timeLimits.max}}
      @value={{@settings.delayTime}}
      @onChange={{fn (mut @settings.delayTime)}}
      @disabled={{not @settings.delayOn}}
    />
    <Synth::Knob
      @label="amount"
      @minValue={{this.delayLimits.min}}
      @maxValue={{this.delayLimits.max}}
      @value={{@settings.delayAmount}}
      @onChange={{fn (mut @settings.delayAmount)}}
      @disabled={{not @settings.delayOn}}
    />
  </div>
  
  */
  {
    "id": "CDaQ4RnH",
    "block": "[[[10,0],[14,0,\"relative flex items-center px-12 pt-32 pb-20\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"absolute top-12 body-text-s text-base-grey-78 lowercase\"],[12],[1,\"\\n    \"],[1,\"delay \"],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@value\",\"@onChange\"],[[30,1,[\"delayOn\"]],[28,[37,1],[[28,[37,2],[[30,1,[\"delayOn\"]]],null]],null]]],null],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@label\",\"@minValue\",\"@maxValue\",\"@value\",\"@onChange\",\"@disabled\"],[\"time\",[30,0,[\"timeLimits\",\"min\"]],[30,0,[\"timeLimits\",\"max\"]],[30,1,[\"delayTime\"]],[28,[37,1],[[28,[37,2],[[30,1,[\"delayTime\"]]],null]],null],[28,[37,4],[[30,1,[\"delayOn\"]]],null]]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@label\",\"@minValue\",\"@maxValue\",\"@value\",\"@onChange\",\"@disabled\"],[\"amount\",[30,0,[\"delayLimits\",\"min\"]],[30,0,[\"delayLimits\",\"max\"]],[30,1,[\"delayAmount\"]],[28,[37,1],[[28,[37,2],[[30,1,[\"delayAmount\"]]],null]],null],[28,[37,4],[[30,1,[\"delayOn\"]]],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@settings\"],false,[\"synth/switch/on-off\",\"fn\",\"mut\",\"synth/knob\",\"not\"]]",
    "moduleName": "music-machine/components/synth/effects/delay.hbs",
    "isStrictMode": false
  });

  class DelayComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "timeLimits", _delay.TIME_LIMITS);

      _defineProperty(this, "amountLimits", _delay.AMOUNT_LIMITS);
    }

  }

  _exports.default = DelayComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DelayComponent);
});