define("music-machine/components/synth/sequencer/step.module.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "step": "step__step_hcoy2a",
    "step--disabled": "step__step--disabled_hcoy2a",
    "inner": "step__inner_hcoy2a",
    "step--active": "step__step--active_hcoy2a",
    "step--active-record": "step__step--active-record_hcoy2a",
    "inAndOut": "step__inAndOut_hcoy2a"
  };
  _exports.default = _default;
});