define("music-machine/components/synth/effects/lfo", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "music-machine/utils/synth/effects/lfo"], function (_exports, _component, _templateFactory, _component2, _lfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="relative flex items-center px-12 pt-32 pb-20">
    <label class="absolute top-12 body-text-s text-base-grey-78 lowercase">
      {{"lfo "}}
    </label>
  
    <Synth::Switch::OnOff
      @value={{@settings.lfoOn}}
      @onChange={{fn (mut @settings.lfoOn)}}
    />
  
    {{#let (array 0 1 2) as |options|}}
      <Synth::Switch
        @options={{options}}
        @value={{@settings.lfoOscillator}}
        @onChange={{fn (mut @settings.lfoOscillator)}}
        as |option|
      >
        {{concat "osc " (add option 1)}}
      </Synth::Switch>
    {{/let}}
    <Synth::WaveFormSwitch @waveForm={{@settings.lfoWaveForm}} />
    {{#let (array "pitch" "volume" "lowpass" "highpass") as |options|}}
      <Synth::Switch
        @label="target"
        @options={{options}}
        @value={{@settings.lfoTarget}}
        @onChange={{fn (mut @settings.lfoTarget)}}
        as |option|
      >
        {{option}}
      </Synth::Switch>
    {{/let}}
    <Synth::Knob
      @label="rate"
      @minValue={{this.rateLimits.min}}
      @maxValue={{this.rateLimits.max}}
      @value={{@settings.lfoRate}}
      @onChange={{fn (mut @settings.lfoRate)}}
    />
    <Synth::Knob
      @label="int"
      @minValue={{this.intLimits.min}}
      @maxValue={{this.intLimits.max}}
      @value={{@settings.lfoInt}}
      @onChange={{fn (mut @settings.lfoInt)}}
    />
  </div>
  
  */
  {
    "id": "R+yDP1bI",
    "block": "[[[10,0],[14,0,\"relative flex items-center px-12 pt-32 pb-20\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"absolute top-12 body-text-s text-base-grey-78 lowercase\"],[12],[1,\"\\n    \"],[1,\"lfo \"],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,0],null,[[\"@value\",\"@onChange\"],[[30,1,[\"lfoOn\"]],[28,[37,1],[[28,[37,2],[[30,1,[\"lfoOn\"]]],null]],null]]],null],[1,\"\\n\\n\"],[44,[[28,[37,4],[0,1,2],null]],[[[1,\"    \"],[8,[39,5],null,[[\"@options\",\"@value\",\"@onChange\"],[[30,2],[30,1,[\"lfoOscillator\"]],[28,[37,1],[[28,[37,2],[[30,1,[\"lfoOscillator\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,6],[\"osc \",[28,[37,7],[[30,3],1],null]],null]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n\"]],[2]]],[1,\"  \"],[8,[39,8],null,[[\"@waveForm\"],[[30,1,[\"lfoWaveForm\"]]]],null],[1,\"\\n\"],[44,[[28,[37,4],[\"pitch\",\"volume\",\"lowpass\",\"highpass\"],null]],[[[1,\"    \"],[8,[39,5],null,[[\"@label\",\"@options\",\"@value\",\"@onChange\"],[\"target\",[30,4],[30,1,[\"lfoTarget\"]],[28,[37,1],[[28,[37,2],[[30,1,[\"lfoTarget\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,5]],[1,\"\\n    \"]],[5]]]]],[1,\"\\n\"]],[4]]],[1,\"  \"],[8,[39,9],null,[[\"@label\",\"@minValue\",\"@maxValue\",\"@value\",\"@onChange\"],[\"rate\",[30,0,[\"rateLimits\",\"min\"]],[30,0,[\"rateLimits\",\"max\"]],[30,1,[\"lfoRate\"]],[28,[37,1],[[28,[37,2],[[30,1,[\"lfoRate\"]]],null]],null]]],null],[1,\"\\n  \"],[8,[39,9],null,[[\"@label\",\"@minValue\",\"@maxValue\",\"@value\",\"@onChange\"],[\"int\",[30,0,[\"intLimits\",\"min\"]],[30,0,[\"intLimits\",\"max\"]],[30,1,[\"lfoInt\"]],[28,[37,1],[[28,[37,2],[[30,1,[\"lfoInt\"]]],null]],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@settings\",\"options\",\"option\",\"options\",\"option\"],false,[\"synth/switch/on-off\",\"fn\",\"mut\",\"let\",\"array\",\"synth/switch\",\"concat\",\"add\",\"synth/wave-form-switch\",\"synth/knob\"]]",
    "moduleName": "music-machine/components/synth/effects/lfo.hbs",
    "isStrictMode": false
  });

  class OscillatorComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "rateLimits", _lfo.RATE_LIMITS);

      _defineProperty(this, "intLimits", _lfo.INT_LIMITS);
    }

  }

  _exports.default = OscillatorComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OscillatorComponent);
});