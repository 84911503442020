define("music-machine/components/synth/knob.module.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "indicator": "knob__indicator_3dhbze",
    "knob": "knob__knob_3dhbze",
    "knob-inner": "knob__knob-inner_3dhbze",
    "knob-inner--yellow": "knob__knob-inner--yellow_3dhbze",
    "knob-inner--blue": "knob__knob-inner--blue_3dhbze",
    "knob-inner--green": "knob__knob-inner--green_3dhbze",
    "background": "knob__background_3dhbze",
    "tick": "knob__tick_3dhbze",
    "tick--t7": "knob__tick--t7_3dhbze",
    "tick--t8": "knob__tick--t8_3dhbze",
    "tick--t9": "knob__tick--t9_3dhbze",
    "tick--t10": "knob__tick--t10_3dhbze",
    "tick--t11": "knob__tick--t11_3dhbze",
    "tick--t1": "knob__tick--t1_3dhbze",
    "tick--t2": "knob__tick--t2_3dhbze",
    "tick--t3": "knob__tick--t3_3dhbze",
    "tick--t4": "knob__tick--t4_3dhbze",
    "tick--t5": "knob__tick--t5_3dhbze",
    "tick--main": "knob__tick--main_3dhbze"
  };
  _exports.default = _default;
});