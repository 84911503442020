define("music-machine/services/settings-change", ["exports", "@ember/service", "@ember/runloop"], function (_exports, _service, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SettingsChangeService extends _service.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "trigger", () => {});

      _defineProperty(this, "trigger", () => {
        (0, _runloop.debounce)(this, this.callback, 100);
      });
    }

    attachCallback(callback) {
      this.callback = callback;
    }

  }

  _exports.default = SettingsChangeService;
});