define("music-machine/utils/synth/effects/lfo", ["exports", "music-machine/utils/synth/base-node", "music-machine/utils/synth/effects/biquad-filter"], function (_exports, _baseNode, _biquadFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RATE_LIMITS = _exports.MIN_RATE = _exports.MIN_INT = _exports.MAX_RATE = _exports.MAX_INT = _exports.LFOSettings = _exports.INT_MULTIPLIERS = _exports.INT_LIMITS = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const MIN_RATE = 0;
  _exports.MIN_RATE = MIN_RATE;
  const MIN_INT = 0.0;
  _exports.MIN_INT = MIN_INT;
  const MAX_RATE = 10;
  _exports.MAX_RATE = MAX_RATE;
  const MAX_INT = 1.0;
  _exports.MAX_INT = MAX_INT;
  const RATE_LIMITS = {
    min: MIN_RATE,
    max: MAX_RATE
  };
  _exports.RATE_LIMITS = RATE_LIMITS;
  const INT_LIMITS = {
    min: MIN_INT,
    max: MAX_INT
  };
  _exports.INT_LIMITS = INT_LIMITS;
  const QUARTER_BIQUAD_MAX_AMOUNT = _biquadFilter.MAX_AMOUNT / 4;
  const INT_MULTIPLIERS = {
    volume: 3.4028,
    pitch: 1000,
    highpass: QUARTER_BIQUAD_MAX_AMOUNT,
    lowpass: QUARTER_BIQUAD_MAX_AMOUNT
  };
  _exports.INT_MULTIPLIERS = INT_MULTIPLIERS;

  class LFOSettings {
    constructor() {
      _defineProperty(this, "waveForm", 'sine');

      _defineProperty(this, "rate", 0.0);

      _defineProperty(this, "int", 0.0);

      _defineProperty(this, "target", 'pitch');
    }

  }

  _exports.LFOSettings = LFOSettings;

  class LFO extends _baseNode.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "audioContext", void 0);

      _defineProperty(this, "oscillator", void 0);

      _defineProperty(this, "oscillatorIndex", void 0);

      _defineProperty(this, "activeTarget", void 0);

      _defineProperty(this, "waveForm", 'sine');

      _defineProperty(this, "rate", 0.0);

      _defineProperty(this, "int", 0.0);

      _defineProperty(this, "target", 'pitch');

      _defineProperty(this, "_on", false);
    }

    set on(on) {
      if (on === this._on) {
        return;
      }

      this._on = on;

      if (!this.gain) {
        return;
      } else if (on) {
        this.gain.connect(this.output);
      } else {
        try {
          this.gain.disconnect(this.output);
        } catch (_e) {// do nothing
        }
      }
    }

    get on() {
      return this._on;
    }

    initialize(audioContext) {
      super.initialize(audioContext);
      this.oscillator = this.audioContext.createOscillator();
      this.gain = this.audioContext.createGain();
      this.apply({
        waveForm: this.waveForm,
        rate: this.rate,
        int: this.int,
        target: this.target,
        on: this.on
      });
      this.oscillator.start();
      this.oscillator.connect(this.gain);
    }

    apply(settings) {
      this.rate = settings.rate;
      this.int = settings.int;
      this.waveForm = settings.waveForm;
      this.target = settings.target;
      this.on = settings.on;

      if (this.oscillator) {
        const now = this.audioContext.currentTime;
        this.oscillator.type = this.waveForm;
        this.oscillator.frequency.setValueAtTime(this.rate, now);
        this.gain.gain.setValueAtTime(INT_MULTIPLIERS[this.target] * this.int, now);
      }
    }

  }

  _exports.default = LFO;
});