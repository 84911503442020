define("music-machine/components/synth/index.module.scss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "section": "index__section_ivd6l8",
    "synth": "index__synth_ivd6l8"
  };
  _exports.default = _default;
});