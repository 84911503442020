define("music-machine/constants/instruments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    name: '__DEFAULT__',
    gain: 1,
    delayOn: false,
    delayAmount: 0,
    delayTime: 0,
    lfoRate: 0,
    lfoInt: 0,
    lfoTarget: 'pitch',
    lfoWaveForm: 'sine',
    lfoOscillator: 0,
    envelopeRelease: 0.04,
    envelopeAttack: 0.05,
    envelopeTarget: 'pitch',
    envelopeInt: 0,
    envelopeOscillator: 0,
    distortion: 0,
    voices: 4,
    oscillators: [{
      waveForm: 'square',
      frequency: 440,
      gain: 0.4509996800000001,
      pitch: 0,
      detune: 0,
      octaveOffset: 0,
      highpass: 0,
      lowpass: 12722.84999999992,
      envelope: {
        attack: 0.08,
        decay: 0.1,
        sustain: 1,
        release: 0.5
      }
    }, {
      waveForm: 'triangle',
      frequency: 440,
      gain: 0.45712472000000004,
      pitch: 0,
      detune: 0,
      octaveOffset: 1,
      highpass: 0,
      lowpass: 16453.71,
      envelope: {
        attack: 0.08,
        decay: 0.1,
        sustain: 1,
        release: 0.5
      }
    }, {
      waveForm: 'sawtooth',
      frequency: 440,
      gain: 0.11412248000000057,
      pitch: 0,
      detune: 0,
      octaveOffset: 1,
      highpass: 7580.790000000067,
      lowpass: 13357.88999999994,
      envelope: {
        attack: 0.08,
        decay: 0.1,
        sustain: 1,
        release: 0.5
      }
    }]
  }, {
    name: 'A crazy one',
    gain: 0.33000001311302185,
    delayOn: false,
    delayAmount: 1.71,
    delayTime: 0.06299999999999999,
    lfoOn: true,
    lfoRate: 0.7919999999999999,
    lfoInt: 0.25019999999999976,
    lfoTarget: 'lowpass',
    lfoWaveForm: 'sine',
    lfoOscillator: 0,
    envelopeRelease: 0.04,
    envelopeAttack: 0.05,
    envelopeTarget: 'pitch',
    envelopeInt: 0,
    envelopeOscillator: 0,
    distortion: 17.1,
    voices: 4,
    oscillators: [{
      waveForm: 'square',
      frequency: 440,
      gain: 1.1798794399999992,
      pitch: 0,
      detune: 0,
      octaveOffset: 0,
      highpass: 0,
      lowpass: 5896.170000000007,
      envelope: {
        attack: 0.05,
        decay: 0.1,
        sustain: 1,
        release: 0.1
      }
    }, {
      waveForm: 'sine',
      frequency: 440,
      gain: 1.243383119999999,
      pitch: 0,
      detune: 0,
      octaveOffset: -2,
      highpass: 0,
      lowpass: 22050,
      envelope: {
        attack: 0.05,
        decay: 0.1,
        sustain: 1,
        release: 0.1
      }
    }, {
      waveForm: 'sine',
      frequency: 440,
      gain: 0,
      pitch: 0,
      detune: 0,
      octaveOffset: 0,
      highpass: 0,
      lowpass: 22050,
      envelope: {
        attack: 0.05,
        decay: 0.1,
        sustain: 1,
        release: 0.1
      }
    }]
  }, {
    name: 'slow wobble',
    gain: 0.33000001311302185,
    delayOn: false,
    delayAmount: 0,
    delayTime: 0,
    lfoOn: true,
    lfoRate: 1.817999999999998,
    lfoInt: 0.16739999999999988,
    lfoTarget: 'lowpass',
    lfoWaveForm: 'sine',
    lfoOscillator: 0,
    envelopeRelease: 0.04,
    envelopeAttack: 0.05,
    envelopeTarget: 'pitch',
    envelopeInt: 0,
    envelopeOscillator: 0,
    distortion: 0,
    voices: 4,
    oscillators: [{
      waveForm: 'triangle',
      frequency: 440,
      gain: 0.5,
      pitch: 0,
      detune: 0,
      octaveOffset: 0,
      highpass: 0,
      lowpass: 7721.910000000007,
      envelope: {
        attack: 0.05,
        decay: 0.1,
        sustain: 1,
        release: 0.1
      }
    }, {
      waveForm: 'sine',
      frequency: 440,
      gain: 0.5,
      pitch: 0,
      detune: 0,
      octaveOffset: -1,
      highpass: 0,
      lowpass: 22050,
      envelope: {
        attack: 0.3560000000000001,
        decay: 0.1,
        sustain: 1,
        release: 0.1
      }
    }, {
      waveForm: 'sine',
      frequency: 440,
      gain: 0.5,
      pitch: 0,
      detune: 0,
      octaveOffset: 0,
      highpass: 0,
      lowpass: 22050,
      envelope: {
        attack: 0.05,
        decay: 0.1,
        sustain: 1,
        release: 0.1
      }
    }]
  }, {
    name: 'Mellow chords',
    gain: 0.33000001311302185,
    delayOn: false,
    delayAmount: 0.6659999999999999,
    delayTime: 0,
    lfoOn: true,
    lfoRate: 0.5399999999999998,
    lfoInt: 0.026999999999999948,
    lfoTarget: 'pitch',
    lfoWaveForm: 'sine',
    lfoOscillator: 1,
    envelopeRelease: 0.04,
    envelopeAttack: 0.05,
    envelopeTarget: 'pitch',
    envelopeInt: 0,
    envelopeOscillator: 0,
    distortion: 0,
    voices: 4,
    oscillators: [{
      waveForm: 'sawtooth',
      frequency: 440,
      gain: 0.7288797600000004,
      pitch: 0,
      detune: 384.48000000000184,
      octaveOffset: 0,
      highpass: 0,
      lowpass: 5856.480000000002,
      envelope: {
        attack: 1.2199999999999995,
        decay: 2.0799999999999996,
        sustain: 1,
        release: 0.9279999999999999
      }
    }, {
      waveForm: 'square',
      frequency: 440,
      gain: 0.8675023999999995,
      pitch: 0,
      detune: 0,
      octaveOffset: 0,
      highpass: 0,
      lowpass: 5022.99,
      envelope: {
        attack: 0.9499999999999997,
        decay: 3.0519999999999987,
        sustain: 1,
        release: 1.0899999999999996
      }
    }, {
      waveForm: 'triangle',
      frequency: 440,
      gain: 0.8085052799999997,
      pitch: 0,
      detune: -311.0399999999993,
      octaveOffset: 0,
      highpass: 0,
      lowpass: 4110.119999999999,
      envelope: {
        attack: 1.2020000000000002,
        decay: 2.2239999999999993,
        sustain: 1,
        release: 1.252
      }
    }]
  }, {
    name: 'hardcore techno mega bass',
    gain: 0.33000001311302185,
    delayOn: false,
    delayAmount: 0,
    delayTime: 0,
    lfoRate: 0,
    lfoInt: 0,
    lfoTarget: 'pitch',
    lfoWaveForm: 'sine',
    lfoOscillator: 0,
    envelopeOn: true,
    envelopeRelease: 1.7370000000000068,
    envelopeAttack: 0.24299999999999874,
    envelopeTarget: 'lowpass',
    envelopeInt: -1,
    envelopeOscillator: 0,
    distortion: 0,
    voices: 4,
    oscillators: [{
      waveForm: 'square',
      frequency: 440,
      gain: 0.8920025599999994,
      pitch: 0,
      detune: -1156.8,
      octaveOffset: -1,
      highpass: 0,
      lowpass: 4665.780000000003,
      envelope: {
        attack: 0.12199999999999998,
        decay: 0.1,
        sustain: 1,
        release: 0.17999999999999997
      }
    }, {
      waveForm: 'square',
      frequency: 440,
      gain: 0,
      pitch: 0,
      detune: -4.319999999999936,
      octaveOffset: -1,
      highpass: 317.5199999999998,
      lowpass: 2919.4200000000014,
      envelope: {
        attack: 0.122,
        decay: 0.1,
        sustain: 1,
        release: 0
      }
    }, {
      waveForm: 'triangle',
      frequency: 440,
      gain: 0.006125039999999999,
      pitch: 0,
      detune: 30.24000000000001,
      octaveOffset: -2,
      highpass: 0,
      lowpass: 10142.999999999985,
      envelope: {
        attack: 0.10399999999999998,
        decay: 0.1,
        sustain: 1,
        release: 0.32400000000000007
      }
    }]
  }, {
    name: 'this was annoying',
    gain: 0.33000001311302185,
    delayOn: true,
    delayAmount: 0.7200000000000005,
    delayTime: 0.05940000000000002,
    lfoOn: true,
    lfoRate: 3.653999999999997,
    lfoInt: 0.7272,
    lfoTarget: 'pitch',
    lfoWaveForm: 'triangle',
    lfoOscillator: 0,
    envelopeOn: true,
    envelopeRelease: 0.8049999999999998,
    envelopeAttack: 0.572,
    envelopeTarget: 'pitch',
    envelopeInt: 0,
    envelopeOscillator: 1,
    distortion: 25.2,
    voices: 5,
    oscillators: [{
      waveForm: 'sine',
      frequency: 440,
      gain: 0.5,
      pitch: 0,
      detune: -142.55999999999972,
      octaveOffset: 2,
      highpass: 10517.849999999995,
      lowpass: 22050,
      envelope: {
        attack: 0.48200000000000004,
        decay: 0.1,
        sustain: 1,
        release: 0.694
      }
    }, {
      waveForm: 'sine',
      frequency: 440,
      gain: 0.5,
      pitch: 0,
      detune: 431.99999999999955,
      octaveOffset: 2,
      highpass: 12343.589999999991,
      lowpass: 22050,
      envelope: {
        attack: 0.46399999999999997,
        decay: 0.1,
        sustain: 1,
        release: 0.4419999999999999
      }
    }, {
      waveForm: 'sine',
      frequency: 440,
      gain: 0.5,
      pitch: 0,
      detune: -1054.0799999999992,
      octaveOffset: 2,
      highpass: 14010.569999999989,
      lowpass: 22050,
      envelope: {
        attack: 0.05,
        decay: 0.1,
        sustain: 1,
        release: 0.604
      }
    }]
  }, {
    name: 'is it some kind of organ?',
    gain: 0.33000001311302185,
    delayOn: false,
    delayAmount: 0.6480000000000001,
    delayTime: 0.17099999999999999,
    lfoOn: true,
    lfoRate: 0.6659999999999999,
    lfoInt: 0.1368000000000007,
    lfoTarget: 'lowpass',
    lfoWaveForm: 'sine',
    lfoOscillator: 1,
    envelopeRelease: 0.04,
    envelopeAttack: 0.05,
    envelopeTarget: 'pitch',
    envelopeInt: 0,
    envelopeOscillator: 0,
    distortion: 5.040000000000005,
    voices: 4,
    oscillators: [{
      waveForm: 'square',
      frequency: 440,
      gain: 0.5,
      pitch: 0,
      detune: 0,
      octaveOffset: 0,
      highpass: 0,
      lowpass: 9706.41,
      envelope: {
        attack: 3.901999999999998,
        decay: 0.1,
        sustain: 1,
        release: 1.133999999999999
      }
    }, {
      waveForm: 'sawtooth',
      frequency: 440,
      gain: 0.5,
      pitch: 0,
      detune: 6,
      octaveOffset: -1,
      highpass: 0,
      lowpass: 10698.660000000002,
      envelope: {
        attack: 3.981999999999998,
        decay: 0.1,
        sustain: 1,
        release: 1.197999999999999
      }
    }, {
      waveForm: 'sawtooth',
      frequency: 440,
      gain: 0.5,
      pitch: 0,
      detune: -6,
      octaveOffset: 1,
      highpass: 6945.749999999996,
      lowpass: 12921.299999999996,
      envelope: {
        attack: 3.931999999999998,
        decay: 0.1,
        sustain: 1,
        release: 1.1079999999999999
      }
    }]
  }, {
    name: 'dreamy bells',
    gain: 0.33000001311302185,
    delayOn: true,
    delayAmount: 0.9540000000000027,
    delayTime: 0.4067999999999996,
    lfoOn: true,
    lfoRate: 2.267999999999998,
    lfoInt: 0.02700000000000025,
    lfoTarget: 'pitch',
    lfoWaveForm: 'sine',
    lfoOscillator: 0,
    envelopeRelease: 0.04,
    envelopeAttack: 0.05,
    envelopeTarget: 'pitch',
    envelopeInt: 0,
    envelopeOscillator: 0,
    distortion: 0,
    voices: 3,
    oscillators: [{
      waveForm: 'sine',
      frequency: 440,
      gain: 0.5,
      pitch: 0,
      detune: 0,
      octaveOffset: 2,
      highpass: 0,
      lowpass: 10658.969999999996,
      envelope: {
        attack: 0,
        decay: 0.1,
        sustain: 1,
        release: 1.1799999999999997
      }
    }, {
      waveForm: 'sine',
      frequency: 440,
      gain: 0.5,
      pitch: 0,
      detune: -2,
      octaveOffset: 1,
      highpass: 0,
      lowpass: 8476.019999999999,
      envelope: {
        attack: 0,
        decay: 0.1,
        sustain: 1,
        release: 1.5039999999999996
      }
    }, {
      waveForm: 'sine',
      frequency: 440,
      gain: 0.43262456000000016,
      pitch: 0,
      detune: 2,
      octaveOffset: 1,
      highpass: 0,
      lowpass: 6729.659999999998,
      envelope: {
        attack: 0.018,
        decay: 0.1,
        sustain: 1,
        release: 1.2879999999999996
      }
    }]
  }];
  _exports.default = _default;
});