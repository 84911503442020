define("music-machine/components/share", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="absolute bottom-0 m-24" local-class="root">
    {{#if this.isSuccess}}
      <p>Url copied to clipboard. <FaIcon @icon="party-horn" @prefix="far" /></p>
    {{else if this.isFail}}
      <p>Failed to copy url. <FaIcon @icon="triangle-exclamation" @prefix="far" /></p>
    {{else}}
      <CopyButton
        class="text-cyan-300 hover:text-brand-primary lowercase transition-colors "
        @clipboardText={{this.getUrl}}
        @success={{this.handleUrlCopySuccess}}
        @error={{this.handleUrlCopyFail}}
      >
        Share your sound <FaIcon @icon="megaphone" @prefix="far" />
      </CopyButton>
    {{/if}}
  </div>
  
  */
  {
    "id": "cwMBYSiY",
    "block": "[[[10,0],[15,0,[29,[\"absolute bottom-0 m-24 \",[28,[37,0],[\"root\"],[[\"from\"],[\"music-machine/components/share.module.scss\"]]]]]],[12],[1,\"\\n\"],[41,[30,0,[\"isSuccess\"]],[[[1,\"    \"],[10,2],[12],[1,\"Url copied to clipboard. \"],[8,[39,2],null,[[\"@icon\",\"@prefix\"],[\"party-horn\",\"far\"]],null],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isFail\"]],[[[1,\"    \"],[10,2],[12],[1,\"Failed to copy url. \"],[8,[39,2],null,[[\"@icon\",\"@prefix\"],[\"triangle-exclamation\",\"far\"]],null],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],[[24,0,\"text-cyan-300 hover:text-brand-primary lowercase transition-colors \"]],[[\"@clipboardText\",\"@success\",\"@error\"],[[30,0,[\"getUrl\"]],[30,0,[\"handleUrlCopySuccess\"]],[30,0,[\"handleUrlCopyFail\"]]]],[[\"default\"],[[[[1,\"\\n      Share your sound \"],[8,[39,2],null,[[\"@icon\",\"@prefix\"],[\"megaphone\",\"far\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]],[]]],[13],[1,\"\\n\"]],[],false,[\"local-class\",\"if\",\"fa-icon\",\"copy-button\"]]",
    "moduleName": "music-machine/components/share.hbs",
    "isStrictMode": false
  });

  const SUCCESS = 'success';
  const FAIL = 'fail';
  let ShareComponent = (_class = class ShareComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "copyState", _descriptor, this);
    }

    get isSuccess() {
      return this.copyState === SUCCESS;
    }

    get isFail() {
      return this.copyState === FAIL;
    }

    handleUrlCopySuccess() {
      this.copyState = SUCCESS;
      setTimeout(() => {
        this.copyState = null;
      }, 4500);
    }

    handleUrlCopyFail() {
      this.copyState = FAIL;
      setTimeout(() => {
        this.copyState = null;
      }, 4500);
    }

    getUrl() {
      return window.location.href;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "copyState", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleUrlCopySuccess", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleUrlCopySuccess"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getUrl", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getUrl"), _class.prototype)), _class);
  _exports.default = ShareComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ShareComponent);
});