define("music-machine/components/synth/label", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label
    local-class="label"
    class="absolute w-full text-center text-base-grey-64 lowercase left-0 bottom-0 mb-0"
    ...attributes
  >{{@label}}</label>
  */
  {
    "id": "AFl5d8Mf",
    "block": "[[[11,\"label\"],[16,0,[29,[\"absolute w-full text-center text-base-grey-64 lowercase left-0 bottom-0 mb-0 \",[28,[37,0],[\"label\"],[[\"from\"],[\"music-machine/components/synth/label.module.scss\"]]]]]],[17,1],[12],[1,[30,2]],[13]],[\"&attrs\",\"@label\"],false,[\"local-class\"]]",
    "moduleName": "music-machine/components/synth/label.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});