define("music-machine/components/synth/switch/octave", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Synth::Switch
    @label="Octave"
    @options={{array 2 1 0 -1 -2}}
    @value={{@oscillator.octaveOffset}}
    @onChange={{fn (mut @oscillator.octaveOffset)}}
    as |option|
  >
    <div
      local-class="indicator {{if
        (eq option @oscillator.octaveOffset)
        "indicator--active"
      }}"
      class="h-8 w-8 rounded-full"
    ></div>
  </Synth::Switch>
  */
  {
    "id": "ZXCwuMka",
    "block": "[[[8,[39,0],null,[[\"@label\",\"@options\",\"@value\",\"@onChange\"],[\"Octave\",[28,[37,1],[2,1,0,-1,-2],null],[30,1,[\"octaveOffset\"]],[28,[37,2],[[28,[37,3],[[30,1,[\"octaveOffset\"]]],null]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[15,0,[29,[\"h-8 w-8 rounded-full \",[28,[37,4],[[28,[37,5],[\"indicator \",[52,[28,[37,7],[[30,2],[30,1,[\"octaveOffset\"]]],null],\"indicator--active\"]],null]],[[\"from\"],[\"music-machine/components/synth/switch/octave.module.scss\"]]]]]],[12],[13],[1,\"\\n\"]],[2]]]]]],[\"@oscillator\",\"option\"],false,[\"synth/switch\",\"array\",\"fn\",\"mut\",\"local-class\",\"concat\",\"if\",\"eq\"]]",
    "moduleName": "music-machine/components/synth/switch/octave.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});