define("music-machine/utils/synth/voice", ["exports", "music-machine/constants/notes", "music-machine/utils/synth/oscillator"], function (_exports, _notes, _oscillator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Voice {
    constructor(oscillatorSettings) {
      _defineProperty(this, "isPlaying", false);

      _defineProperty(this, "startTime", 0);

      _defineProperty(this, "oscillators", []);

      this.oscillators = oscillatorSettings.map(setting => {
        return new _oscillator.default(setting);
      });
    }

    initialize(audioContext) {
      this.audioContext = audioContext;
      this.oscillators.forEach(oscillator => {
        oscillator.initialize(audioContext);
      });
    }

    apply(settings) {
      this.oscillators.forEach((oscillator, index) => {
        oscillator.apply(settings[index]);
      });
    }

    addEffect(effect, oscillatorIndex, target) {
      this.oscillators[oscillatorIndex].addEffect(effect, target);
    }

    removeEffect(effect, oscillatorIndex, target) {
      this.oscillators[oscillatorIndex].removeEffect(effect, target);
    }

    connect(node) {
      this.oscillators.forEach(oscillator => oscillator.connect(node));
    }

    play(note, octave) {
      this.isPlaying = true;
      this.startTime = this.audioContext.currentTime;
      this.oscillators.forEach(oscillator => oscillator.play(this.getFrequency(octave, note, oscillator)));
      return {
        stop: () => {
          this.stop();
        }
      };
    }

    getFrequency(octave, note, oscillator) {
      const calculatedOctave = octave + oscillator.octaveOffset;

      if (_notes.default[calculatedOctave][note]) {
        return _notes.default[calculatedOctave][note];
      } else if (calculatedOctave <= 0) {
        return _notes.default[0].A;
      } else {
        return _notes.default[8].C;
      }
    }

    stop() {
      this.isPlaying = false;
      this.oscillators.forEach(oscillator => oscillator.stop());
    }

  }

  _exports.default = Voice;
});