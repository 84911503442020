define("music-machine/templates/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jTADqxUD",
    "block": "[[[11,\"img\"],[24,0,\"hidden lg:block absolute inset-0 h-screen w-screen transition-all duration-400 ease-out\"],[24,\"src\",\"https://media3.giphy.com/media/cOzK12kNVHoiOLYX6P/giphy.gif?cid=6104955etmcu6ea57cwqr68yyqgngy6v0u5lbu3hammj092a&rid=giphy.gif&ct=g\"],[4,[38,0],[[30,0,[\"didInsertGif\"]]],null],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"relative min-h-screen flex items-center justify-center p-16\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@onNoteChange\",\"@onInitAudioContext\",\"@onSettingsChanged\",\"@initialSettings\"],[[30,0,[\"handleNoteChange\"]],[30,0,[\"handleInitAudioContext\"]],[30,0,[\"handleSettingsChanged\"]],[30,0,[\"initialSettings\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,2],null,null,null],[1,\"\\n\"],[8,[39,3],null,null,null],[1,\"\\n\"]],[],false,[\"did-insert\",\"synth\",\"instructions\",\"share\"]]",
    "moduleName": "music-machine/templates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});